import moment from "moment";
import { formatMoney } from "../../../utils/formatMoney";
import DropDownComp from "../../Ui/DropDown";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ImageComponent } from "../../Common/FallbackImage";

export const FieldRepColumns = (tableDropDown={},Data=[]) => {
    return [
      {
        name: "ID",
        selector: (row) => Data?.length > 0 && Data?.findIndex((item) => item?.id === row?.id) + 1,
        width:'70px',
        sortable: true,
      },
   
      {
        name: "Photos",
        selector: (row) => 
        <ImageComponent src='/Images/noImage.png' width={50} height={50}/>,
        

          sortable: true,
        width:'200px'
      },
  
      {
        name: "Rep Full Name",
        selector: (row) => <div>{row?.firstName} {row?.lastName}</div>,

          sortable: true,
          width:'200px'
      },
      {
        name: "Date",
        selector: (row) => moment(row?.createdAt).format("MMM Do YY"),
        sortable: true,
        width:'150px'
      },
      {
        name: "Rating",
        selector: (row) => '-',
        sortable: true,
        // width:'70px'
  
      },
      // {
      //   name: "Availability",
      //   selector: (row) => '-',
      //   sortable: true,
      //    width:'200px'
      // },
      {
        name: <div>Last Active</div>,
        selector: (row) => row?.active?<div className="border-[#044E54] border-[1px] rounded-lg px-[16px] capitalize py-[4px] bg-[#e6eeee]">active</div>:<div className="border-[#E9E911] border-[1px] rounded-lg px-[16px] capitalize py-[4px] bg-[#f3efe1]">In-Active</div>,
        sortable: true,
        width:'200px'
      },//#E9E911
     
      {
        name: "Action",
        // selector: (row) => <DropDownComp dropDownText={<BsThreeDotsVertical color="#7572EA" />}/>,
        cell: (row) => ( <DropDownComp row={row} options={tableDropDown} dropDownText={<BsThreeDotsVertical color="#7572EA" />}/>),
        // width:'100px'
      },
    ];
  };