import React, { useEffect, useState } from "react";
import ListViewInCanvas from "../../../Common/ListViewInCanvas";
import { ImageComponent } from "../../../Common/FallbackImage";
import ButtonComp from "../../../Ui/Button";
import { formatMoney } from "../../../../utils/formatMoney";
import SelectComp from "../../../Ui/SelectComp";
import {
//   useGetCategoriesQuery,
  useUpdateProductMutation,
} from "../../../../store/Product/ProductApi";
import { handleKeyPress } from "../../../../utils/helper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function ViewProductDetails({ data, onSuccess,handleCancel,productId }) {
  const { t, i18n, ready } = useTranslation();

  const [edit, setEdit] = useState(false);
  const [userData, setUserData] = useState(data);
  const [picture, setPicture] = useState(null);
  const [image, setImage] = useState(null);
  //   const {data:getCategories,isLoading,} =useGetCategoriesQuery()
  const [
    updateProduct,
    { isLoading: updateProductLoader, isSuccess: updateProductIsSuccess },
  ] = useUpdateProductMutation();
  //
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    // console.log(e.target)
  };
  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    setUserData(data)
  }, [])
  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (updateProductIsSuccess) {
      onSuccess();
    }
  }, [updateProductIsSuccess, onSuccess]);

  const handleUpdateProduct = () => {
     const id = userData?.id||productId;
    const form = new FormData();
    // alert(productId)
    form.append("productName", userData?.productName);
    form.append("productSku", userData?.productSku);
    form.append("composition", userData?.composition);
    form.append("costPerUnit", userData?.costPerUnit);
    form.append("nafdacNo", userData?.nafdacNo);
    form.append("shelfLife", userData?.shelfLife);
    form.append("categoryId", userData?.categoryId);
    form.append("productImage", picture);
    form.append("status", userData?.status);
    // console.log(userData, picture, "onSend");
    updateProduct({ form, id })
      .unwrap()
      .then((data) => {
        // console.log(data, "Data");
        toast.success("Product Updated Successfully");
      })
      .catch((err) => {
        // console.log(err, "Error");
        if (Array.isArray(err?.data?.message)) {
          toast.error(err?.data?.message[0]);
        } else {
          toast.error(err?.data?.message);
        }
      });
  };

  // console.log(userData,data,'handleOnChange')
  return (
    <div>
      <div className="border-[rgba(79, 0, 138, 0.1)] border-[1px] py-[50px] pl-[20px] xl:pl-[30px] pr-[20px] xl:pr[0px] flex-1 mb-[30px]">
        <div className="row gy-4">
          <div className="col-lg-6 ">
            <ListViewInCanvas
              title={t("lang.PN")}
              ItemName={data?.productName}
              edit={edit}
              value={userData?.productName}
              name="productName"
              onChange={handleOnChange}
            />
          </div>
          <div className="col-lg-6 ">
            <ListViewInCanvas
             title={t("lang.PS")}
              ItemName={data?.productSku}
              edit={edit}
              name="productSku"
              onChange={handleOnChange}
            />
          </div>
          <div className="col-lg-6 ">
            <ListViewInCanvas
              title={"Composition"}
              ItemName={data?.composition}
              edit={edit}
              name="productComposition"
              onChange={handleOnChange}
            />
          </div>
          <div className="col-lg-6 ">
            <ListViewInCanvas
              title={"Categorys"}
              ItemName={"-"}
              edit={edit}
              // name='productSku'
              // onChange={handleOnChange}
            />
          </div>
          <div className="col-lg-6 ">
            <ListViewInCanvas
              title={"NAFDAC Registration Number"}
              ItemName={data?.nafdacNo}
              edit={edit}
              name="nafdacNo"
              onChange={handleOnChange}
            />
          </div>
          <div className="col-lg-6 ">
            <ListViewInCanvas
              title={"Product Price (NGN)"}
              ItemName={
                !edit
                  ? formatMoney(data?.costPerUnit, true, "")
                  : data?.costPerUnit
              }
              edit={edit}
              name="costPerUnit"
              onChange={handleOnChange}
              onKeyPress={handleKeyPress}
            />
          </div>

          <div className="col-lg-6 ">
            <ListViewInCanvas
              title={"Expiry Date"}
              ItemName={
              data?.shelfLife
              }
              // value={data?.shelfLife}
              edit={edit}
              type="number"
              name='shelfLife'
              onChange={handleOnChange}
              minLength={1}

            />
          </div>
          <div className="col-lg-6 ">
            <ListViewInCanvas
              title={"Description"}
              ItemName={"-"}
              edit={edit}
            />
          </div>
          <div className="col-lg-6 ">
            <ListViewInCanvas
              title={"Availability"}
             
              edit={edit}
              customItemI={
                <SelectComp
                  options={[
                    { name: "Available", id: "Available" },
                    // {name:'Discontinued',id:'discontinued '},
                    { name: "Out of stock", id: "Out of stock" },
                  ]}
                  name="status"
                  onChange={handleOnChange}
                />
              }
              //1px solid #044E54
              customItem={
                <div className="flex gap-4 text-[14px] text-[#282728] font-medium items-center gap-1 border-[1px] border-[#044E54] py-[5px] pl-[10px] pr-[20px] bg-[#E6EEEE]">
                  {data?.status === "Available" ? "In-Stock" : "Out-Of-Stock"}
                  <ImageComponent
                    src="/icon/select.png"
                    width={8}
                    height={18}
                  />
                </div>
              }
            />
          </div>
          <div className="col-lg-6 ">
            {!edit ? (
              <ImageComponent
                src={
                  data?.productPhotoUrl
                    ? data?.productPhotoUrl
                    : "/Images/noImage.png"
                }
                className="max-w-[120px] h-full object-cover rounded-[5px] max-h-[60px]"
              />
            ) : (
              <div className="flex gap-[10px] items-center">
                <ImageComponent
                  src={image || data?.productPhotoUrl || "/Images/noImage.png"}
                  className="w-[64px] h-[64px]"
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  name="productImageCheck"
                  // onChange={updateField}
                  //   error={errors.productImage}
                  onChange={(e) => {
                    setPicture(e.target.files[0]);
                    //convert Image to be display
                    handleImageChange(e);
                    // updateField(e)
                  }}
                />
                <ButtonComp
                  onClick={handleButtonClick}
                  text={"Choose Photo"}
                  btnClassName={
                    "px-[12px] py-[10px] bg-[#F6F2F9] text-[#282728] font-medium"
                  }
                />
                <div></div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center gap-[20px]">
        <ButtonComp
          onClick={handleCancel}
          text={"Delete Product"}
          btnClassName={
            "border-[#D63434] border-[1px] text-[#282728] rounded-full px-[25px] py-2 text-[14px] font-medium"
          }
        />
        <ButtonComp
          loader={updateProductLoader}
          onClick={() => (!edit ? setEdit(true) : handleUpdateProduct())}
          text={edit ? "Update Product" : "Edit Product"}
          btnClassName={
            "border-[#4F008A] bg-[#4F008A] border-[1px]  rounded-full px-[35px] text-white py-2 text-[14px] font-medium"
          }
        />
      </div>
    </div>
  );
}
