import React from 'react'
import { Card } from 'react-bootstrap'

export default function ContainerComp({children,containerClassName,bodyClassName}) {
  return (
    <Card className={containerClassName}>
    <Card.Body className={bodyClassName}>{children}</Card.Body>
  </Card>
  )
}
