import React from 'react'
import ButtonComp from '../../Ui/Button'
import { t } from 'i18next'
const Company_Profile = () => {
  return (
    <div>
        <div className='flex flex-col md:flex-row  justify-between items-center'> 

            <div className='text-center   md:text-start mt-8'>  
                <h1 className='text-lg md:text-start font-bold' > {t(`lang.CP`)}</h1>
                <p className='text-sm'> {t(`lang.UYCPDH`)} </p>
            </div>

            <div className='flex space-x-5'>
            <ButtonComp
        
        text={t(`lang.D`)}
          btnClassName={"  px-5 border-2  border-[#DE5A5A] py-2 rounded-pill"}
        />
                <ButtonComp
        
        text={t(`lang.SC`)}
        btnClassName={"  bg-[#3DA84A] text-white px-5 py-2 rounded-pill"}
      />
            </div>
        </div>


        <div  className='md:flex mt-10 '>
            <div className='md:w-[50%] w-full pr-4 '>
            <form className="space-y-4 md:space-y-6" action="#">

               <div className=''>
                   <label for="name" className="block mb-2 text-sm font-bold text-[#4F008A] "> {t(`lang.CL`)}</label>
                    <div className='flex flex-row items-center  space-x-2'>
                      <img src='./icon/user.png' className='h-10' />
                      <button className='text-sm font-semibold bg-[#F6F2F9] py-1 px-4 rounded-md'>  {t(`lang.CPP`)} </button>
                      <p className='text-sm mt-2'> {t(`lang.jpeg`)} </p>
                    </div>
                  </div>
                  <div>
                          <label for="name" className="block mb-2 text-sm font-bold text-[#4F008A]">{t(`lang.CN`)}</label>
                          <input   placeholder="PharmaServe LTD" className=" border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2" required=""/>
                      </div>

                  <div>
                      <label for="email" className="block mb-2 text-sm font-bold text-[#4F008A]"> {t(`lang.CA`)} </label>
                      <input type="email" name="password" id="password" placeholder="PharmaServe LTD" className=" border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2" required=""/>
                  </div>
                  
                  
              </form>

            </div>

            <div className='md:w-[50%] md:pl-8 w-full'>
                  <div>
                      <label for="phoneNumber" className="block  text-sm font-bold text-[#4F008A]"> {t(`lang.PNN`)} </label>
                      <input type="contact" name="contact" id="contact" placeholder="+234 909393833" className=" border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2" required=""/>
                  </div>

                  <div className='flex mt-[1.8rem] space-x-5'>
                      <div>
                          <label for="name" className="block mb-2 text-sm font-bold text-[#4F008A]">{t(`lang.S`)}</label>
                          <input   placeholder="Olakunbi" className=" border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2" required=""/>
                      </div>
                      <div>
                          <label for="name" className="block mb-2 text-sm font-bold text-[#4F008A]"> {t(`lang.CITY`)}</label>
                          <input   placeholder="Olabode" className=" border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2" required=""/>
                      </div>
                  </div>

                  <div className='mt-4'>
                      <label  className="block mb-1 text-sm font-bold text-[#4F008A]"> {t(`lang.CA`)} </label>
                      <input  placeholder="PharmaServe LTD" className=" border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2" required=""/>
                  </div>
            </div>

        </div>
    </div>
  )
}

export default Company_Profile
