import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const DashBoardApi = createApi({
  reducerPath: "DashBoardApi",
  baseQuery: baseQuery,
  tagTypes:['getNotification'],
  endpoints: (builder) => ({
    dashboard_metric: builder.query({
      query: ({StartDateEnhanced, EndDateEnhanced}) => ({
        url: `/metrics/orders-range?startDate=${moment(StartDateEnhanced).valueOf() / 1000
        }&endDate=${moment(EndDateEnhanced).valueOf() / 1000}`,
        method: "GET",
      }),
    }),
    graphData: builder.query({
      query: ({StartDateEnhanced,EndDateEnhanced}) => ({
        url: `/metrics/charts-range?startDate=${moment(StartDateEnhanced).valueOf() / 1000
      }&endDate=${moment(EndDateEnhanced).valueOf() / 1000}`,
        method: "GET",
      }),
    }),
    // graphData: builder.query({
    //   query: ({monthId}) => ({
    //     url: `/metrics/charts?months=${monthId}`,
    //     method: "GET",
    //   }),
    // }),
    topAreasData: builder.query({
      query: (month) => ({
        url: `/metrics/top-areas`,
        method: "GET",
      }),
    }),
    mainDashBoardMap: builder.query({
      query: (companyId) => ({
        url: `company/staffs-locations/${companyId}`,
        method: "GET",
      }),
    }),
    fieldReps: builder.query({
      query: (companyId) => ({
        url: `/company/sales-rep`,
        method: "GET",
      }),
    }),
    NotificationList: builder.query({
      query: (companyId) => ({
        url: `notifications`,
        method: "GET",
      }),
      providesTags:(_)=>['getNotification']
    }),
    readNotification: builder.mutation({
      query: (id) => ({
        url: `notifications/${id}/read`,
        method: "PUT",
        body
      }),
      // invalidatesTags:['getNotification']
    }),
    readNotificationII: builder.mutation({
      query: ({id}) => ({
        url: `notifications/${id}/read`,
        method: "PUT",
      }),
      invalidatesTags:['getNotification']
    }),
  }),
});

export const {
  useDashboard_metricQuery,
  useGraphDataQuery,
  useTopAreasDataQuery,
  useMainDashBoardMapQuery,
  useFieldRepsQuery,
  useNotificationListQuery,
  useReadNotificationMutation,
  useReadNotificationIIMutation

} = DashBoardApi;
