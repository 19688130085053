import React from 'react'
import { Offcanvas } from 'react-bootstrap'
import { ImageComponent } from '../Common/FallbackImage'

export default function OffCanvasComp({show=true,handleClose,bodyComponent,title,serialNo,ButtonComp}) {
  return (
    <Offcanvas show={show} onHide={handleClose} placement='end'>
        <Offcanvas.Header  className='mx-2'>
          <div className='w-full'>
          <div className='mt-[10px] flex justify-between items-end w-full cursor-pointer' onClick={handleClose}>
            <ImageComponent
            src='/icon/close.svg'
            />
          
          </div>
          <div className='mt-[30px] flex justify-between items-center w-full'>
          <h5 className='mb-0 font-semibold'>{title}</h5>
           {serialNo && <h5 className='mb-0 font-semibold'>{serialNo}</h5>}
           {ButtonComp && ButtonComp}
          </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {bodyComponent}
        </Offcanvas.Body>
      </Offcanvas>
  )
}
