import React, { useState } from "react";
import classNames from "classnames";
import Personal_information from "./Personal_information";
import Password from "./Password";
import FieldRep from "./FieldRep";
import Company_Profile from "./Company_Profile";
import Privacy_Policy from "./Privacy_Policy";
import { selectCurrentUserData } from "../../../store/auth";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Beta from "../../Common/beta";
import { useTranslation } from "react-i18next";

const Admin_Console = () => {
  const [activeTab, setActiveTab] = useState("personal");
  const userDetails =useSelector(selectCurrentUserData);
  const [userData,setUserData] =useState(userDetails);
  const { t } = useTranslation();

  useEffect(() => {
    setUserData(userData)
  }, [userData])
  

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="">
      <div>
        <h1 className="text-2xl font-semibold"> {t(`lang.AC`)} </h1>
      </div>

      <div className="mt-10">
        <div className="w-full">
          <ul className="flex flex-row bg-[#F6F2F9] items-center w-full  text-sm p-2 md:p-4 space-x-1 rounded-md overflow-auto">
            <li>
              <button
                className={classNames(
                  "w-max p-2 px-4 font-semibold rounded-md",
                  {
                    "bg-white text-[#4F008A] ": activeTab === "personal",
                  }
                )}
                onClick={() => handleTabClick("personal")}
              >
                {t(`lang.PI`)}  <Beta/>
              </button>
            </li>
            <li>
              <button
                className={classNames(
                  "w-max p-2 px-4 font-semibold rounded-md",
                  {
                    "bg-white text-[#4F008A]": activeTab === "password",
                  }
                )}
                onClick={() => handleTabClick("password")}
              >
                {t(`lang.PW`)} <Beta/>
              </button>
            </li>

            <li>
              <button
                className={classNames(
                  "w-max p-2 px-4 font-semibold rounded-md",
                  {
                    "bg-white text-[#4F008A]": activeTab === "notification",
                  }
                )}
                onClick={() => handleTabClick("notification")}
              >
                {t(`lang.NFC`) } <Beta/>
              </button>
            </li>

            <li>
              <button
                className={classNames(
                  "w-max p-2 px-4 font-semibold rounded-md",
                  {
                    "bg-white text-[#4F008A]": activeTab === "field-rep",
                  }
                )}
                onClick={() => handleTabClick("field-rep")}
              >
                 {t(`lang.FR`) } 
              </button>
            </li>

            <li>
              <button
                className={classNames(
                  "w-max p-2 px-4 font-semibold rounded-md",
                  {
                    "bg-white text-[#4F008A]": activeTab === "company",
                  }
                )}
                onClick={() => handleTabClick("company")}
              >
                   {t(`lang.CP`) }  <Beta/>
              </button>
            </li>

            <li>
              <button
                className={classNames("w-max p-2 px-4 font-semibold", {
                  "bg-white text-[#4F008A] rounded-md": activeTab === "privacy",
                })}
                onClick={() => handleTabClick("privacy")}
              >
           {t(`lang.PPPP`) }
              </button>
            </li>
          </ul>
        </div>
        <div className="w-full">
          {activeTab === "personal" && <Personal_information  userData={userData}/>}
          {activeTab === "password" && <Password />}
          {activeTab === "notification" && <div>Notification</div>}
          {activeTab === "field-rep" && <FieldRep />}
          {activeTab === "company" && <Company_Profile />}
          {activeTab === "privacy" && <Privacy_Policy />}
        </div>
      </div>
    </div>
  );
};

export default Admin_Console;
