import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
//api/v1/company/set-currency
export const transactionsApi = createApi({
  reducerPath: "transactionsApi",
  baseQuery: baseQuery,
  
  endpoints: (builder) => ({
    getOverviewTransaction: builder.query({
      query: (month) => ({
        url: `/transactions/overview/?year=${'2023'}&month=${month}`,
        method: "GET",
       
      }),
    }),
    getRecentTransaction: builder.query({
      query: (month) => ({
        url: `/transactions/recent`,
        method: "GET",
       
      }),
    }),
    getFilterRecentTransaction: builder.query({
      query: ({fundingSource,paymentId,startDate,endDate}) => ({
        url: `/transactions/user-history?startDate=${startDate}&endDate=${endDate}&paymentId=${paymentId}&fundingSource=${fundingSource||'self'}`,
        method: "GET",
       
      }),
    }),
    selectCurrency: builder.mutation({
      query: (payload) => ({
        url: `/company/set-currency`,
        method: "PUT",
        body: payload,
      }),
      //
//https://backend.pharmaserv.ng/dev/api/v1/company/set-currency
      // transformResponse: (response) => response.data,
      //https://backend.pharmaserv.ng/dev/api/v1/company/set-currency
    }),
    
  }),
 
  
});

export const {
  useGetOverviewTransactionQuery,useGetRecentTransactionQuery,useGetFilterRecentTransactionQuery,useSelectCurrencyMutation
} = transactionsApi;
