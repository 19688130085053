import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
// import AddProductComp from '../../Components/modules/Product/addproduct'
import ShowMapComp from '../../Components/modules/Schedule/showMap'

export default function ShowMap() {
  return (
    <AdminLayout active={5} noPadding>
    <ShowMapComp/>
  </AdminLayout>
  )
}
